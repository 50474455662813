<template>
  <div class="mobile-menu">
    <button
      class="btn btn--close"
      @click="toggleMobileMenu()"
    ><i class="btn__icn las la-times"></i>
    </button>
    <Navigation
      :layout="'mobile'"
    />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
export default {
  name: 'mobilemenu',
  props: '',
  components: {
    Navigation
  },
  data () {
    return {
    }
  },
  methods: {
    toggleMobileMenu () { this.$store.commit('toggleMobileMenu') }
  },
  mounted () {
  }
}

</script>
