<template>
  <div class="home">
    <Hero
      :layout="'home'"
      :title="siteTagline"
      :background="heroBg"
    />

    <Banner
      :layout="'text'"
      :content="{
        text: bannerText,
        bgImg: bannerBg
      }"
    />

    <Cards
      :layout="'cta'"
      :content="homeCta"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Hero from '@/components/Hero.vue'
import Banner from '@/components/Banner.vue'
import Cards from '@/components/Cards.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    Banner,
    Cards
  },
  computed: {
  },
  data () {
    return {
      data: null,
      heroBg: null,
      siteTagline: null,
      pageData: null,
      bannerText: null,
      bannerBg: null,
      ctaData: null,
      homeCta: null
    }
  },
  mounted () {
    axios.get('https://api.goatclub.org/wp-json/gc/v2/website')
      .then(function (response) {
        this.siteTagline = response.data.tagline
      }.bind(this))

    axios.get('https://api.goatclub.org/wp-json/gc/v2/pages')
      .then(function (response) {
        this.pageData = response.data[4] // TODO: DOTHATBETTER
        this.heroBg = this.pageData.featured_image
        this.bannerText = this.pageData.acf.presentation_text
        this.bannerBg = this.pageData.acf.presentation_background
        this.homeCta = this.pageData.acf.cta
      }.bind(this))
  }
}
</script>
