<template>
  <div
    class="card"
    :class="layout ? cardLayout : ''"
  >
    <!-- GALLERY CARD -->
    <div v-if="layout == 'gallery'">
      <div class="card__content">
        <h3 class="card__title">{{ content.title }}</h3>
        <div class="card__subtitle">{{ content.caption }}</div>
      </div>
      <div
        class="card__bg"
        :style="{backgroundImage: 'url(' + content.url + ')'}"
      >
      </div>
    </div>

    <!-- CTA CARD -->
    <a v-else-if="layout" :href="'#/' + content.post_name" class="card__link">
      <h3 class="card__title">{{ content.post_name }}</h3>
      <div
        class="card__bg"
        :style="{ backgroundImage: 'url(' + require('@/assets/' + content.post_name + '.jpg') + ')' }"
      >
      </div>
    </a>

    <!-- BAND CARD (DEFAULT) -->
    <div v-else class="card__content">
      <h3 class="card__title">{{ content.post_title }}</h3>
      <div class="card__subtitle">{{ content.acf.genre }} <span v-if="content.acf.location && content.acf.genre"> • </span> {{ content.acf.location }}</div>
      <div class="card__volume">
        <i class="card__volume__icon las la-volume-up"></i>
        <div
          v-for="index of volume" :key="index"
          class="card__volume__level"
        >
        </div>
      </div>
      <div class="card__links">
        <a v-if="content.acf.music" class="card__link" :href="content.acf.music" target="_blank" noopener><i class="las la-record-vinyl"></i></a>
        <a v-if="content.acf.video" class="card__link" :href="content.acf.video" target="_blank" noopener><i class="las la-film"></i></a>
        <a v-if="content.acf.website" class="card__link" :href="content.acf.website" target="_blank" noopener><i class="las la-link"></i></a>
        <button
          v-if="content.acf.email"
          class="card__link"
          @click="toggleModal(content.acf.email, content.post_title)"
          noopener>
            <i class="las la-envelope"></i>
          </button>
      </div>

      <div
        class="card__bg"
        :style="{backgroundImage: 'url(' + content.featured_image_url + ')'}"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    layout: String,
    content: [Array, Object]
  },
  components: {
  },
  computed: {
    volume () {
      var v = []
      for (let i = 0; i < this.content.acf.volume; i++) {
        v.push(i)
      }
      return v
    },
    cardLayout () {
      return 'card--' + this.layout
    }
  },
  data () {
    return {
      selectedBand: null
    }
  },
  methods: {
    toggleModal (bandEmail, bandName) {
      this.$store.commit('toggleModal')
      this.$store.state.modalForm = 'band'

      this.$store.state.contactedBand = {
        name: bandName,
        email: bandEmail
      }
    }
  },
  mounted () {
  }
}

</script>
