<template>
  <div id="app" class="app">
    <Modal
      :class="{'modal--isOpened': modalStatus}"
    />
    <MobileMenu :class="{'mobile-menu--isOpened': mobileMenuStatus}" />
    <HeaderMenu />

    <main>
      <router-view />
    </main>

    <Foot />
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import Foot from '@/components/Foot.vue'
import HeaderMenu from '@/components/HeaderMenu.vue'

export default {
  name: 'App',
  props: '',
  components: {
    Modal,
    MobileMenu,
    HeaderMenu,
    Foot
  },
  computed: {
    modalStatus () {
      return this.$store.state.modalOpened
    },
    mobileMenuStatus () {
      return this.$store.state.mobileMenuOpened
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}

</script>
