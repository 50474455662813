<template>
  <div class="hero"
    :class="layout == 'home' ? 'hero--home' : ''"
  >
    <div v-if="layout == 'home'" class="hero__content">
      <Logo class="logo--pink" />
      <h3 class="hero__title">{{ title }}</h3>
    </div>

    <div v-else class="hero__content container">
      <div class="row">
        <div v-if="title" class="col-12"><h1>{{ title }}</h1></div>
        <div v-if="text" class="col-12 col-md-9 offset-md-3">
          <p class="hero__text --lead" v-html="text"></p>
        </div>
      </div>
    </div>

    <div v-if="background" class="hero__bg" :style="{backgroundImage: 'url(' + background + ')'}"></div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'

export default {
  name: 'hero',
  props: {
    title: String,
    text: String,
    layout: String,
    background: String
  },
  components: {
    Logo
  },
  data () {
    return {
      logoColor: 'pink'
    }
  },
  methods: {
  },
  mounted () {
  }
}

</script>
