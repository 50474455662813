<template>
  <div class="manifeste">
    <Hero
      :title="heroTitle"
      :text="heroText"
      :background="heroBg"
    />
    <div class="content container">
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10" v-html="content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Hero from '@/components/Hero.vue'
export default {
  name: 'manifeste',
  props: '',
  components: {
    Hero
  },
  data () {
    return {
      pageData: null,
      heroTitle: null,
      heroText: null,
      heroBg: null,
      content: null
    }
  },
  methods: {
  },
  mounted () {
    axios.get('https://api.goatclub.org/wp-json/gc/v2/pages')
      .then(function (response) {
        this.pageData = response.data[3] // TODO: DOTHATBETTER
        this.heroTitle = this.pageData.title
        this.heroText = this.pageData.acf.introduction
        this.heroBg = this.pageData.featured_image
        this.content = this.pageData.content
      }.bind(this))
  }
}

</script>
