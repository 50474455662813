<template>
  <div class="header">
    <div class="col-12 header__content">
      <a href="/#/"><Logo class="logo--small logo--white nav__logo" /></a>
      <Navigation />
      <button
        class="btn btn--burger"
        @click="toggleMobileMenu"
      >
        <i class="btn__icn las la-bars"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import Logo from '@/components/Logo.vue'

export default {
  name: 'HeaderMenu',
  props: '',
  components: {
    Logo,
    Navigation
  },
  data () {
    return {
    }
  },
  methods: {
    toggleMobileMenu () { this.$store.commit('toggleMobileMenu') }
  },
  mounted () {
  }
}

</script>
