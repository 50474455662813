import Vue from 'vue'
import VueRouter from 'vue-router'
import Artistes from '../views/Artistes.vue'
import Home from '../views/Home.vue'
import Resistance from '../views/Resistance.vue'
import Rejoindre from '../views/Rejoindre.vue'
import Manifeste from '../views/Manifeste.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/artistes',
    name: 'Artistes',
    component: Artistes
  },
  {
    path: '/resistance',
    name: 'La Resistance',
    component: Resistance
  },
  {
    path: '/manifeste',
    name: 'Manifeste',
    component: Manifeste
  },
  {
    path: '/rejoindre',
    name: 'Rejoindre',
    component: Rejoindre
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Styleguide.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
