<template>
  <div
    class="nav"
    :class="layout ? navLayout : ''"
  >
    <div class="nav__items" @click="clickMenuItem()">
      <router-link class="nav__item" to="/manifeste">Manifeste</router-link>
      <router-link class="nav__item" to="/artistes">Artistes</router-link>
      <router-link class="nav__item" to="/rejoindre">Rejoindre</router-link>
      <router-link class="nav__item" to="/resistance">La Résistance</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  props: {
    layout: String
  },
  components: {
  },
  computed: {
    navLayout () {
      return 'nav--' + this.layout
    }
  },
  data () {
    return {
    }
  },
  methods: {
    clickMenuItem () {
      if (this.layout === 'mobile') {
        this.$store.commit('closeMobileMenu')
      }
    }
  },
  mounted () {
  }
}

</script>
