<template>
  <div class="foot">
    <div class="container">
      <div class="row flex-column flex-lg-row">
        <div class="col-12 col-lg-2">
          <a href="/#/"><Logo class="logo--small logo--white foot__logo" /></a>
        </div>
        <div class="col-12 col-lg-6">
          <Nav
            :layout="'foot'"
          />
        </div>
        <div class="foot__contact col-12 col-lg-4">
          <button
            @click="toggleModal()"
            class="foot__contact__btn btn btn--small"
          >
            Contactez le GoatClub
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import Nav from '@/components/Navigation.vue'

export default {
  name: 'foot',
  props: '',
  components: {
    Logo,
    Nav
  },
  data () {
    return {
    }
  },
  methods: {
    toggleModal () {
      this.$store.commit('toggleModal')
      this.$store.state.modalForm = 'contact'
    }
  },
  mounted () {
  }
}

</script>
