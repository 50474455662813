<template>
  <div class="contact">
    <Hero
      :title="heroTitle"
      :text="heroText"
      :background="heroBg"
    />
    <div class="container">
      <div class="row">
        <div class="col-12 offset-lg-2 col-lg-8">
          <div
            class="form"
            :class="{'form--is-sent': formIsSent}"
          >
            <div class="form__success">
              <h3>Merci d'avoir rempli le formulaire, nous vous répondrons sous peu.</h3>
            </div>

            <form
              class="form__form"
              @submit.prevent="formSubmit"
            >
              <fieldset class="fieldset">
                <input
                  id="name"
                  class="input"
                  v-model="formData.name"
                  type="text"
                  placeholder="Nom"
                  name="name"
                  required
                >
              </fieldset>
              <fieldset class="fieldset">
                <input
                  id="email"
                  class="input"
                  v-model="formData.email"
                  type="email"
                  placeholder="Courriel"
                  name="email"
                  required
                >
              </fieldset>

              <fieldset class="fieldset">
                <input
                  id="showname"
                  class="input"
                  v-model="formData.showName"
                  type="text"
                  placeholder="Nom du spectacle"
                  name="showname"
                  required
                >
              </fieldset>

              <div class="fieldset fieldset--row">
                <input
                  id="genre"
                  class="input"
                  v-model="formData.genre"
                  type="text"
                  placeholder="Genre"
                  name="genre"
                >
                <input
                  id="location"
                  class="input"
                  v-model="formData.location"
                  type="text"
                  placeholder="Localisation"
                  name="location"
                >
              </div>

              <div class="fieldset fieldset--row">
                <select
                  id="volume"
                  class="select"
                  name="volume"
                  v-model="formData.volume"
                >
                  <option :value="null" disabled>Volume</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>

                <select
                  id="peopleNumber"
                  class="select"
                  name="peopleNumber"
                  v-model="formData.peopleNumber"
                >
                  <option :value="null" disabled>Nombre d'Artiste(s)</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>

              <fieldset class="fieldset">
                <input
                  id="music_url"
                  class="input"
                  v-model="formData.musicUrl"
                  type="text"
                  placeholder="Lien vers musique"
                  name="music_url"
                  required
                >
              </fieldset>

              <fieldset class="fieldset">
                <input
                  id="video_url"
                  class="input"
                  v-model="formData.videoUrl"
                  type="text"
                  placeholder="Lien vers vidéo"
                  name="video_url"
                  required
                >
              </fieldset>

              <fieldset class="fieldset">
                <input
                  id="web_url"
                  class="input"
                  v-model="formData.webUrl"
                  type="text"
                  placeholder="Lien vers site web"
                  name="web_url"
                  required
                >
              </fieldset>

              <fieldset class="fieldset form__terms">
                <label class="checkbox">
                  <span class="checkbox__input">
                    <input
                      type="checkbox"
                      name="checkbox"
                      v-model="formData.goatClubTerms"
                      required
                    >
                    <span class="checkbox__control"></span>
                  </span>
                  <span class="checkbox__label">
                    J'ai lu et suis 100% en phase avec le <a href="/#/manifeste">manifeste du Goat Club</a>
                  </span>
                </label>

                <label class="checkbox">
                  <span class="checkbox__input">
                    <input
                      type="checkbox"
                      name="checkbox"
                      v-model="formData.autonomyTerms"
                      required
                    >
                    <span class="checkbox__control"></span>
                  </span>
                  <span class="checkbox__label">
                    Je suis 100% autonome pour la mise en place de ma performance
                  </span>
                </label>
              </fieldset>

              <input class="btn form__submit" type="submit" value="Envoyer">
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import emailjs from 'emailjs-com'

import Hero from '@/components/Hero.vue'
export default {
  name: 'rejoindre',
  props: '',
  components: {
    Hero
  },
  data () {
    return {
      pageData: null,
      heroTitle: null,
      heroText: null,
      heroBg: null,
      errors: [],
      formIsSent: false,
      formData: {
        name: null,
        email: null,
        showName: null,
        genre: null,
        location: null,
        volume: null,
        peopleNumber: null,
        musicUrl: null,
        videoUrl: null,
        webUrl: null,
        goatClubTerms: null,
        autonomyTerms: null
      }
    }
  },
  methods: {
    formSubmit () {
      const self = this

      emailjs.send('service_ayrs2ln', 'template_12jz0b4', this.formData)
        .then(function (response) {
          console.log(response)
          self.formIsSent = !self.formIsSent
        }, function (error) {
          console.log('FAILED...', error)
        })
    }
  },
  mounted () {
    emailjs.init('user_oqRzP2KquwpiBwtFVaHWh')
    axios.get('https://api.goatclub.org/wp-json/gc/v2/pages')
      .then(function (response) {
        this.pageData = response.data[0] // TODO: DOTHATBETTER
        this.heroTitle = this.pageData.title
        this.heroText = this.pageData.acf.introduction
        this.heroBg = this.pageData.featured_image
      }.bind(this))
  }
}

</script>
