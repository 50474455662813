import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function bodyScrollToggle (f) {
  if (f === true) {
    document.body.style.overflowY = 'hidden'
  } else {
    document.body.style.overflowY = 'auto'
  }
}

export const store = new Vuex.Store({
  state: {
    modalOpened: false,
    mobileMenuOpened: false,
    contactedBand: {
      name: null,
      email: null
    },
    modalForm: null
  },
  mutations: {
    toggleModal: state => {
      state.modalOpened = !state.modalOpened
      bodyScrollToggle(state.modalOpened)
    },
    toggleMobileMenu: state => {
      state.mobileMenuOpened = !state.mobileMenuOpened
      bodyScrollToggle(state.mobileMenuOpened)
    },
    closeMobileMenu: state => {
      state.mobileMenuOpened = false
      document.body.style.overflowY = 'auto'
    }
  }
})
