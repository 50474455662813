<template>
  <div class="artistes">
    <Hero
      :title="heroTitle"
      :text="heroText"
      :background="heroBg"
    />

    <div class="container">
      <Cards
        :content="artistes"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Hero from '@/components/Hero.vue'
import Cards from '@/components/Cards.vue'

export default {
  name: 'artistes',
  props: '',
  components: {
    Hero,
    Cards
  },
  data () {
    return {
      pageData: null,
      artistes: null,
      heroTitle: null,
      heroText: null,
      heroBg: null
    }
  },
  methods: {
  },
  mounted () {
    axios.get('https://api.goatclub.org/wp-json/gc/v2/artistes')
      .then(function (response) {
        this.artistes = response.data
      }.bind(this))
    axios.get('https://api.goatclub.org/wp-json/gc/v2/pages')
      .then(function (response) {
        this.pageData = response.data[2] // TODO: DOTHATBETTER
        this.heroTitle = this.pageData.title
        this.heroText = this.pageData.acf.introduction
        this.heroBg = this.pageData.featured_image
        this.content = this.pageData.content
      }.bind(this))
  }
}

</script>
