<template>
  <div class="modal">
    <button
      class="btn btn--close"
      @click="toggleModal()"
    ><i class="btn__icn las la-times"></i>
    </button>

    <div class="modal__content">
      <FormBand
        v-if="contactFormType === 'band'"
        :contactedBand="band"
      />
      <FormContact
        v-if="contactFormType === 'contact'"
      />
    </div>
  </div>
</template>

<script>
import FormBand from '@/components/FormBand.vue'
import FormContact from '@/components/FormContact.vue'
export default {
  name: 'modal',
  props: '',
  components: {
    FormBand,
    FormContact
  },
  data () {
    return {
    }
  },
  computed: {
    band () { return this.$store.state.contactedBand },
    contactFormType () { return this.$store.state.modalForm }
  },
  methods: {
    toggleModal () {
      this.$store.commit('toggleModal')
    }
  },
  mounted () {
  }
}

</script>
