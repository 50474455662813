<template>
  <div class="formband">
    <div
      class="form"
      :class="{'form--is-sent': formIsSent}"
    >
      <div class="form__success">
        <h3>Merci d'avoir rempli le formulaire, {{ contactedBand.name }} vous répondra probablement.</h3>
      </div>

      <form
        class="form__form"
        @submit.prevent="formSubmit"
      >
        <h2>Contacter {{ contactedBand.name }}</h2>
        <br /><br />
        <fieldset class="fieldset">
          <input
            id="name"
            class="input"
            v-model="formData.name"
            type="text"
            placeholder="Votre Nom"
            name="name"
            required
          >
        </fieldset>
        <fieldset class="fieldset">
          <input
            id="email"
            class="input"
            v-model="formData.email"
            type="email"
            placeholder="Votre Courriel"
            name="email"
            required
          >
        </fieldset>

        <fieldset class="fieldset">
          <input
            id="location"
            class="input"
            v-model="formData.location"
            type="text"
            placeholder="Votre localité"
            name="location"
          >
        </fieldset>

        <fieldset class="fieldset">
          <textarea
            id="message"
            class="input"
            v-model="formData.message"
            placeholder="Votre message"
            rows="5"
            name="message"
          ></textarea>
        </fieldset>

        <fieldset class="fieldset form__terms">
          <label class="checkbox">
            <span class="checkbox__input">
              <input
                type="checkbox"
                name="checkbox"
                v-model="formData.goatClubTerms"
                required
              >
              <span class="checkbox__control"></span>
            </span>
            <span class="checkbox__label">
              J'ai lu et suis 100% en phase avec le <a href="/#/manifeste" @click="toggleModal()">manifeste du Goat Club</a>
            </span>
          </label>
        </fieldset>

        <input class="btn form__submit" type="submit" value="Envoyer">
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com'

export default {
  name: 'formband',
  props: {
    contactedBand: Object
  },
  components: {
  },
  data () {
    return {
      formIsSent: false,
      formData: {
        bandName: null,
        bandEmail: null,
        name: null,
        email: null,
        location: null,
        goatClubTerms: null
      }
    }
  },
  methods: {
    formSubmit () {
      const self = this
      this.formData.bandName = this.contactedBand.name
      this.formData.bandEmail = this.contactedBand.email

      emailjs.send('service_ayrs2ln', 'template_06a3ylh', this.formData)
        .then(function (response) {
          console.log(response)
          self.formIsSent = !self.formIsSent
        }, function (error) {
          console.log('FAILED...', error)
        })
    },
    toggleModal () {
      this.$store.commit('toggleModal')
    }
  },
  mounted () {
    emailjs.init('user_oqRzP2KquwpiBwtFVaHWh')
  }
}

</script>
