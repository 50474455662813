<template>
  <div class="formcontact">
    <div
      class="form"
      :class="{'form--is-sent': formIsSent}"
    >
      <div class="form__success">
        <h3>Merci d'avoir rempli le formulaire. <br><br> Le Goat Club vous répondra probablement.</h3>
      </div>

      <form
        class="form__form"
        @submit.prevent="formSubmit"
      >
        <h2>Contacter le Goat Club</h2>
        <br /><br />
        <fieldset class="fieldset">
          <input
            id="name"
            class="input"
            v-model="formData.name"
            type="text"
            placeholder="Votre Nom"
            name="name"
            required
          >
        </fieldset>
        <fieldset class="fieldset">
          <input
            id="email"
            class="input"
            v-model="formData.email"
            type="email"
            placeholder="Votre Courriel"
            name="email"
            required
          >
        </fieldset>

        <fieldset class="fieldset">
          <input
            id="subject"
            class="input"
            v-model="formData.subject"
            type="text"
            placeholder="Sujet"
            name="subject"
          >
        </fieldset>

        <fieldset class="fieldset">
          <textarea
            id="message"
            class="input"
            v-model="formData.message"
            placeholder="Votre message"
            rows="5"
            name="message"
          ></textarea>
        </fieldset>

        <input class="btn form__submit" type="submit" value="Envoyer">
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com'

export default {
  name: 'formcontact',
  props: {
    contactedBand: Object
  },
  components: {
  },
  data () {
    return {
      formIsSent: false,
      formData: {
        name: null,
        email: null,
        subject: null,
        message: null
      }
    }
  },
  methods: {
    formSubmit () {
      const self = this

      emailjs.send('service_ayrs2ln', 'template_3tvevy2', this.formData)
        .then(function (response) {
          console.log(response)
          self.formIsSent = !self.formIsSent
        }, function (error) {
          console.log('FAILED...', error)
        })
    },
    toggleModal () {
      this.$store.commit('toggleModal')
    }
  },
  mounted () {
    emailjs.init('user_oqRzP2KquwpiBwtFVaHWh')
  }
}

</script>
