<template>
  <div
    class="banner"
    :class="layout ? bannerLayout : ''"
  >
    <div class="banner__content">
      <p class="--lead" v-html="content.text"></p>
    </div>
    <div class="banner__bg" :style="{backgroundImage: 'url(' + content.bgImg + ')'}"></div>
  </div>
</template>

<script>
export default {
  name: 'banner',
  props: {
    layout: String,
    content: Object
  },
  components: {
  },
  computed: {
    bannerLayout () {
      return 'banner--' + this.layout
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}

</script>
