<template>
  <div
    class="cards"
    :class="layout ? cardsLayout : ''"
  >
    <Card
      v-for="card in content" :key="card.id"
      :layout="layout"
      :content="card"
    />
  </div>
</template>

<script>
import Card from '@/components/Card.vue'

export default {
  name: 'cards',
  props: {
    layout: String,
    content: Array
  },
  components: {
    Card
  },
  computed: {
    cardsLayout () {
      return 'cards--' + this.layout
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}

</script>
