<template>
  <div class="resistance">
    <Hero
      :title="heroTitle"
      :text="heroText"
      :background="heroBg"
    />
    <div class="container-fluid">
      <Cards
        :content="gallery"
        :layout="'gallery'"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Hero from '@/components/Hero.vue'
import Cards from '@/components/Cards.vue'

export default {
  name: 'resistance',
  props: '',
  components: {
    Hero,
    Cards
  },
  data () {
    return {
      pageData: null,
      artistes: null,
      heroTitle: null,
      heroText: null,
      heroBg: null,
      gallery: null
    }
  },
  methods: {
  },
  mounted () {
    axios.get('https://api.goatclub.org/wp-json/gc/v2/pages')
      .then(function (response) {
        this.pageData = response.data[1] // TODO: DOTHATBETTER
        this.heroTitle = this.pageData.title
        this.heroText = this.pageData.acf.introduction
        this.gallery = this.pageData.acf.gallery
        this.heroBg = this.pageData.featured_image
        this.content = this.pageData.content
      }.bind(this))
  }
}

</script>
