<template>
  <div class="logo">
    <svg version="1.1" class="logo__svg" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 150 38.28" xml:space="preserve">
        <g>
          <path class="logo__g" d="M15.22,36.04c-0.14-0.03-0.2-0.1-0.2-0.2c-0.44,0.41-0.96,0.72-1.55,0.94c-0.59,0.22-1.22,0.38-1.88,0.48
            c-0.66,0.1-1.32,0.16-1.99,0.18c-0.66,0.02-1.3,0.03-1.91,0.03H6.77c-0.81,0-1.63-0.09-2.44-0.25c-0.81-0.17-1.54-0.45-2.19-0.84
            c-0.65-0.39-1.16-0.89-1.55-1.5C0.19,34.26,0,33.51,0,32.63c0-0.51,0.1-1.12,0.31-1.83L6.97,8.75c0.88-3.09,2.16-5.32,3.84-6.69
            C12.5,0.69,14.79,0,17.71,0c0.37,0,0.72,0.01,1.04,0.03c0.32,0.02,0.62,0.04,0.89,0.08c0.24,0,0.32,0.12,0.25,0.36l-2.44,7.13
            c-0.07,0.14-0.15,0.2-0.25,0.2c-0.1,0-0.19-0.07-0.25-0.2c-0.1-0.31-0.44-0.46-1.02-0.46c-1.15,0-1.87,0.59-2.14,1.78L7.58,29.47
            c-0.07,0.2-0.12,0.39-0.15,0.56c-0.03,0.17-0.05,0.29-0.05,0.36c0,0.51,0.39,0.76,1.17,0.76c0.58,0,1.02-0.14,1.32-0.41
            c0.31-0.27,0.58-0.75,0.81-1.43l4.17-13.18c0.03-0.1,0.12-0.15,0.25-0.15h6.06c0.24,0,0.32,0.1,0.25,0.31l-5.9,19.6
            C15.46,36.02,15.35,36.07,15.22,36.04z"/>
          <path class="logo__o" d="M28.3,4.45c0.68-1.14,1.46-2.04,2.34-2.7c0.88-0.66,1.87-1.12,2.98-1.37C34.72,0.13,35.99,0,37.41,0
            c2.14,0,3.77,0.31,4.89,0.94c1.12,0.63,1.68,1.82,1.68,3.59c0,1.15-0.25,2.6-0.76,4.33L37,29.52c-0.54,1.73-1.15,3.16-1.83,4.3
            c-0.68,1.14-1.46,2.04-2.34,2.7c-0.88,0.66-1.88,1.12-3,1.37c-1.12,0.25-2.38,0.38-3.77,0.38c-2.14,0-3.78-0.31-4.91-0.94
            c-1.14-0.63-1.71-1.82-1.71-3.59c0-0.58,0.07-1.22,0.2-1.93c0.14-0.71,0.34-1.51,0.61-2.39l6.21-20.67
            C27.01,7.02,27.62,5.59,28.3,4.45z M26.93,30.03c-0.03,0.17-0.05,0.29-0.05,0.36c0,0.51,0.39,0.76,1.17,0.76
            c0.58,0,1.02-0.14,1.32-0.41c0.31-0.27,0.58-0.75,0.81-1.43v0.05l6.21-20.56c0.1-0.34,0.15-0.63,0.15-0.87
            c0-0.54-0.37-0.81-1.12-0.81c-1.15,0-1.87,0.59-2.14,1.78l-6.21,20.56C27.01,29.67,26.96,29.86,26.93,30.03z"/>
          <path class="logo__a" d="M35.73,37.46c-0.07-0.07-0.07-0.15,0-0.25L51.92,0.87c0.1-0.1,0.19-0.15,0.25-0.15h8.4
            c0.17,0,0.25,0.1,0.25,0.31l-5.7,36.34c0,0.14-0.09,0.2-0.25,0.2h-6.52c-0.24,0-0.32-0.1-0.25-0.31l1.12-6.11h-3.87l-2.49,6.26
            c-0.07,0.1-0.15,0.15-0.25,0.15h-6.62C35.88,37.56,35.8,37.53,35.73,37.46z M53.7,10.03l-5.5,14h2.44L53.7,10.03z"/>
          <path class="logo__t" d="M62.71,7.84c-0.24,0-0.32-0.1-0.25-0.31l2.04-6.62c0.07-0.14,0.15-0.2,0.25-0.2h15.68
            c0.24,0,0.32,0.1,0.25,0.31l-2.04,6.62c-0.07,0.14-0.15,0.2-0.25,0.2h-4.48l-8.86,29.52c-0.07,0.14-0.15,0.2-0.25,0.2h-6.31
            c-0.24,0-0.32-0.1-0.25-0.31l8.81-29.42H62.71z"/>
          <path class="logo__c" d="M83.3,2.06C84.98,0.69,87.27,0,90.19,0c2.21,0,3.83,0.38,4.86,1.15c1.03,0.76,1.55,1.98,1.55,3.64
            c0,1.09-0.24,2.44-0.71,4.07L94.37,14c-0.07,0.14-0.15,0.2-0.25,0.2H87.8c-0.24,0-0.32-0.1-0.25-0.31l1.58-5.45
            c0.07-0.27,0.1-0.42,0.1-0.46c0-0.27-0.1-0.48-0.31-0.64c-0.2-0.15-0.44-0.23-0.71-0.23c-0.48,0-0.89,0.16-1.25,0.48
            c-0.36,0.32-0.67,0.94-0.94,1.86l-5.85,19.44c-0.17,0.68-0.25,1.15-0.25,1.43c0,0.54,0.31,0.81,0.92,0.81
            c0.34,0,0.68-0.08,1.02-0.25c0.1-0.07,0.19-0.08,0.28-0.03c0.08,0.05,0.11,0.14,0.08,0.28l-1.88,6.87c-0.07,0.14-0.15,0.2-0.25,0.2
            s-0.2,0.01-0.31,0.03c-0.1,0.02-0.2,0.03-0.31,0.03h-0.61c-2.21,0-3.82-0.37-4.84-1.12c-1.02-0.75-1.53-1.93-1.53-3.56
            c0-1.15,0.25-2.55,0.76-4.17l6.21-20.67C80.34,5.67,81.62,3.44,83.3,2.06z"/>
          <path class="logo__l" d="M89.68,37.26l10.94-36.34c0.07-0.14,0.15-0.2,0.25-0.2h6.31c0.24,0,0.32,0.1,0.25,0.31l-8.86,29.42h6.31
            c0.24,0,0.32,0.1,0.25,0.31l-1.99,6.62c-0.07,0.14-0.15,0.2-0.25,0.2H89.94C89.7,37.56,89.62,37.46,89.68,37.26z"/>
          <path class="logo__u" d="M121.8,33.82c-0.68,1.14-1.46,2.04-2.34,2.7c-0.88,0.66-1.88,1.12-3,1.37c-1.12,0.25-2.38,0.38-3.77,0.38
            c-2.14,0-3.78-0.31-4.91-0.94c-1.14-0.63-1.71-1.82-1.71-3.59c0-0.58,0.07-1.22,0.2-1.93c0.14-0.71,0.34-1.51,0.61-2.39l8.55-28.5
            c0.07-0.14,0.15-0.2,0.25-0.2h6.31c0.24,0,0.32,0.1,0.25,0.31l-8.25,27.38c-0.27,0.92-0.41,1.56-0.41,1.93
            c0,0.34,0.08,0.56,0.25,0.66c0.17,0.1,0.44,0.15,0.81,0.15c0.58,0,1.03-0.18,1.37-0.53c0.34-0.36,0.7-1.13,1.07-2.32l8.25-27.38
            c0.07-0.14,0.15-0.2,0.25-0.2h6.31c0.24,0,0.32,0.1,0.25,0.31l-8.55,28.5C123.09,31.25,122.48,32.69,121.8,33.82z"/>
          <path class="logo__b" d="M130.71,14.71l8.3,0.05c0.31,0,0.58-0.04,0.81-0.13c0.24-0.08,0.46-0.25,0.66-0.51c0.2-0.25,0.4-0.6,0.59-1.04
            c0.19-0.44,0.38-1.03,0.59-1.78c0.34-0.41,0.51-0.8,0.51-1.17c0-0.07-0.01-0.13-0.03-0.2c-0.02-0.07-0.03-0.13-0.03-0.2
            c0.07-0.2,0.1-0.37,0.1-0.51c0-0.17,0-0.32,0-0.46c0-0.37-0.08-0.62-0.23-0.74c-0.15-0.12-0.42-0.18-0.79-0.18h-0.2
            c-0.07,0-0.17-0.01-0.31-0.03c-0.14-0.02-0.45-0.03-0.94-0.05c-0.49-0.02-1.25-0.03-2.27-0.03c-0.98,0-2.38,0-4.17,0
            c-0.24,0-0.32-0.1-0.25-0.31l1.99-6.52c0.07-0.14,0.15-0.2,0.25-0.2h9.21c1.9,0,3.29,0.36,4.17,1.09c0.88,0.73,1.32,1.82,1.32,3.28
            c0,0.51-0.05,1.04-0.15,1.58c-0.1,0.54-0.24,1.14-0.41,1.78l-0.97,3.16c-0.37,1.36-0.98,2.64-1.81,3.84
            c-0.83,1.2-2.01,2.13-3.54,2.77c1.46,0.51,2.19,1.63,2.19,3.36c0,0.44-0.04,0.89-0.13,1.35c-0.09,0.46-0.21,0.98-0.38,1.55
            l-1.68,5.45c-0.78,2.54-1.9,4.45-3.36,5.73c-1.46,1.27-3.46,1.91-6.01,1.91h-9.42c-0.24,0-0.32-0.1-0.25-0.31l6.36-22.35
            C130.52,14.78,130.61,14.71,130.71,14.71z M133,30.44h1.12c0.41,0,0.75-0.04,1.04-0.13c0.29-0.08,0.55-0.28,0.79-0.59
            c0.24-0.31,0.47-0.75,0.71-1.32c0.24-0.58,0.51-1.36,0.81-2.34v0.05c0.24-0.85,0.42-1.54,0.53-2.09c0.12-0.54,0.18-0.97,0.18-1.27
            c0-0.37-0.11-0.61-0.33-0.71c-0.22-0.1-0.57-0.15-1.04-0.15h-1.27L133,30.44z"/>
        </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'logo',
  props: {
  },
  components: {
  },
  computed: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}

</script>
